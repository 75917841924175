import { useWindowSize } from "hooks/useWindowSize";
import { ReactNode, createContext, useContext, useState, useEffect } from "react";



export interface ToggleSidebar {
  isOpen: boolean;
  toggleSidebar: () => void;
}

interface IProps {
  children?: ReactNode;
}

const ToggleSidebarContext = createContext<ToggleSidebar | null>(null);

export const ToggleSidebarProvider = ({ children }: IProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const toggleSidebar = () => setIsOpen((prev) => !prev);

  const { width } = useWindowSize();

  useEffect(() => {
    let lastScrollTop = 0;
  
    const preventPullToRefresh = (e: TouchEvent) => {
      const currentScrollTop = e.touches[0].clientY;
  
      // Check if the page is scrolled to the top
      if ( currentScrollTop > lastScrollTop && isOpen && width <= 576) {
        e.preventDefault();
      }
      lastScrollTop = currentScrollTop;
    };
  
    document.addEventListener('touchmove', preventPullToRefresh, { passive: false });
  
    return () => {
      document.removeEventListener('touchmove', preventPullToRefresh);
    };
  }, [isOpen, width]);

  return (
    <ToggleSidebarContext.Provider value={{ isOpen, toggleSidebar }}>
      {children}
    </ToggleSidebarContext.Provider>
  );
};

export const useToggleSidebar = (): ToggleSidebar => {
  const toggleSidebarContext = useContext(ToggleSidebarContext);

  if (!toggleSidebarContext) {
    throw new Error(
      "ToggleSidebarContext must be used within ToggleSidebarContext"
    );
  }

  return toggleSidebarContext;
};
