import { useCallback } from "react";
import { useEffectOnce } from "react-use";
import styled from "styled-components";

import { LoadingPage } from "components";
import { useSelector } from "redux/hooks";

import useRouter from "hooks/useRouter";
import { useAppNotification } from "hooks/services/AppNotification";

import {
  acceptTeamInvitation,
  getAllTeams,
  getCreditLimits,
} from "redux/actions";
import { RoutePaths } from "pages/routePaths";

interface IProps {
  isAuthenticated?: boolean;
}

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const AcceptInvitationPage = ({ isAuthenticated }: IProps) => {
  const { query, push } = useRouter();
  const { triggerNotification } = useAppNotification();
  const {userDetail} = useSelector((state)=> state.authReducer);
  const lang = userDetail?.user?.language?userDetail?.user?.language:"en";
  const Invite = localStorage.getItem('invite');
  

  const acceptInvite = (token: string) => {
    if(Invite === "true")  return;
    acceptTeamInvitation({ token: token })
      .then((res: any) => {        
        push(`/${RoutePaths.Chat}`);
        triggerNotification({ message: res?.message, type: "info" });
        getAllTeams().catch((err) =>
          triggerNotification({ message: err?.data?.message, type: "error" })
        );
        getCreditLimits().catch((err) =>
          triggerNotification({ message: err?.data?.message, type: "error" })
        );
        localStorage.setItem('invite', "true");
      })
      .catch((err) => {
        triggerNotification({ message: err?.data?.message, type: "error" })
        push(`/${lang}/${RoutePaths.Login}`);
        localStorage.removeItem('invite');
        console.error(err?.data?.message);
      });
    
  };

  const onAcceptInvitaion = useCallback(() => {
    if (isAuthenticated) {
      if (Object.keys(query).length > 0) {
        acceptInvite(query.token);
      } else {
        triggerNotification({
          message: "Invitation token is required",
          type: "error",
        });
        push(`/${RoutePaths.Chat}`);
      }
    } else {
      if (Object.keys(query).length > 0) {
        acceptInvite(query.token);
      } else {
        triggerNotification({
          message: "Invitation token is required",
          type: "error",
        });
        push(`/${lang}/${RoutePaths.Login}`);
      }
    }
  }, [query, isAuthenticated]);

  useEffectOnce(() => {
    onAcceptInvitaion()
});

  return (
    <Container>
      <LoadingPage />
    </Container>
  );
};
