import {
  useEffect, useState, memo,
  Dispatch, SetStateAction
} from "react";
import classNames from "classnames";
import styles from "./ChatItem.module.scss";

import { Spinner, Tooltip } from "components";
import { WordHighlighter } from "./WordHighlighter";

import {
  EditIcon,
  TickIcon,
  RemoveIcon,
  DeleteIcon,
  NewImageIcon,
  NewChatIcon,
  ImageGenerationIcon
} from "../icons";

import {
  IChat, updateChatHistory,
  removeMultipleChat,
  deleteSingleChatHistory
} from "redux/actions";

import useRouter from "hooks/useRouter";
import { RoutePaths } from "pages/routePaths";

import { useAppNotification } from "hooks/services/AppNotification";
import { useSelector } from "redux/hooks";
import { NewDocumentIcon } from "../icons/NewDocumentIcon";
interface IProps {
  chatItem: IChat;
  selectedItem?: IChat;
  onSelectChat?: (itemId: IChat | undefined) => void;
  searchQuery: string;
  handleSelectId: (id: number) => void;
  selectedChatIds: number[];
  setSelectedChatIds: Dispatch<SetStateAction<number[]>>;
  onStartNewChat?: ({ toChat }: { toChat: boolean }) => void;
  setSelectedItem: (chatItem: IChat | undefined) => void;
}

export const ChatItem = memo(
  ({ chatItem, selectedItem, setSelectedItem, onSelectChat, searchQuery, 
      handleSelectId, selectedChatIds, setSelectedChatIds, onStartNewChat }: IProps) => {
    const themeMode = useSelector((state) => state.authReducer.theme);
    const { chathistory } = useSelector((state) => state.chatReducer);
    const { triggerNotification } = useAppNotification();
    const { push } = useRouter();

    // const isSearch: boolean = searchQuery.length > 0 ? true : false;
    const isActive: boolean = selectedItem?.id === chatItem.id;

    const [message, setMessage] = useState<string>("");
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [isDelete, setIsDelete] = useState<boolean>(false);
    const [isloading, setIsloading] = useState<boolean>(false);

    useEffect(() => {
      const currentPath = window.location.pathname;
      const pathSegments = currentPath.split('/');
      const chatId = pathSegments[pathSegments.length - 1];
      if (chatItem.id === Number(chatId)) {
        setSelectedItem(chatItem);
      }
      else if (chatId === 'new') { setSelectedItem(undefined) }
    }, [window.location.pathname, chathistory]);

    useEffect(() => {
      if(!isEdit)
      setMessage(selectedItem?.topic as string)
    }, [selectedItem]);

    useEffect(() => {
      if (!message && !isEdit)
        { 
          setMessage(selectedItem?.topic as string);
        }
    }, [selectedItem, message, isEdit]);

    const toggleEdit = () =>
      setIsEdit((prev) => {
        return !prev;
      });

    const onEdit = () => {
      setIsloading(true);
      updateChatHistory(selectedItem?.id as number, message)
        .then((resp: any) => {
          let index = chathistory.findIndex(
            (chat) => chat.id === resp.id
          );
          if (index >= 0) {
            setTimeout(() => {
              setIsloading(false);
              setMessage("");
              toggleEdit();
            }, 500);

          }
        })
        .catch((err) => {
          setIsloading(false);
          triggerNotification({ message: err?.data?.message, type: "error" });
        });
    };

    const toggleDelete = () =>
      setIsDelete((prev) => {
        return !prev;
      });

    const onDelete = () => {
      setIsloading(true);

      const currentPath = window.location.pathname;

      const pathSegments = currentPath.split('/');
      const chatIdFromUrl = pathSegments[pathSegments.length - 1];

      if ((chatItem.id === selectedItem?.id)) {
        push(`/${RoutePaths.Chat}`);
      }
      if (chatItem.id === Number(chatIdFromUrl)) {
        onStartNewChat?.({ toChat: true });
      }
      toggleDelete();
      deleteSingleChatHistory(chatItem.id)
      setSelectedChatIds(prevSelectedChatIds => {
        if (prevSelectedChatIds.includes(chatItem.id)) {
          return prevSelectedChatIds.filter(selectedId => selectedId !== chatItem.id);
        }
        return prevSelectedChatIds;
      });

      removeMultipleChat([chatItem.id])
        .then((resp: any) => {
          if (resp?.message === "Chats deleted successfully") {
          }
          setIsloading(false);
        })
        .catch((err) => {
          setIsloading(false);
          triggerNotification({ message: err?.data?.message, type: "error" });
        });
    };

    const onConfirm = () => {
      if (isDelete) onDelete();
      else onEdit();
    };

    const onCancel = () => {
      if (isDelete) toggleDelete();
      else toggleEdit();
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
      e.stopPropagation();
      handleSelectId(id);
    };


    return (
      <Tooltip
        control={
          <div
            className={classNames(styles.itemContainer, {
              [styles.itemContainerDark]: themeMode === "dark",
              [styles.itemContainerLight]: themeMode === "light",
              [styles.active]: isActive,
            })}
            onClick={() => onSelectChat?.(chatItem)}
            data-testid='select-chat'
          >
            <div className={styles.check}>
              <input type="checkbox"
                checked={selectedChatIds.includes(chatItem.id)}
                onChange={(e) => handleCheckboxChange(e, chatItem.id)}
                onClick={(e) => e.stopPropagation()}
                className={selectedChatIds.includes(chatItem.id) ? styles.visibleCheckbox : styles.hiddenCheckbox}
              />
              {chatItem.chat_type === "text" && (
                <div className={styles.actionIcon} style={{ marginTop: "3px" }}>
                  <NewChatIcon />
                </div>
              )}
              {(chatItem.chat_type === "document") && (
                <div className={styles.actionIcon} style={{ marginLeft: "1.5px" }}>
                  <NewDocumentIcon />
                </div>
              )}
              {(chatItem.chat_type === "image_chat") && (
                <div className={styles.actionIcon}>
                  <NewImageIcon />
                </div>
              )}
              {chatItem.chat_type === "image" && (
                <div className={styles.actionIcon}>
                  <ImageGenerationIcon />
                </div>
              )}

            </div>
            <div
              className={classNames(styles.itemMsgActionContainer, {
                [styles.itemMsgActionContainerLight]: themeMode === "light",
                [styles.itemMsgActionContainerDark]: themeMode === "dark",
                [styles.active]:  isActive,
                [styles.isDeleted]: isDelete
              })}
            >
              <div className={classNames(styles.itemMessageContainer)}>
                {isEdit ? (
                  <input
                    className={classNames(styles.editItemTextfield, {
                      [styles.light]: themeMode === "light",
                      [styles.dark]: themeMode === "dark",
                    })}
                    type="text"
                    autoFocus
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onClick={(e) => e.stopPropagation()}
                    data-testid='edit-input'
                  />
                ) : (
                  <div
                    className={classNames(styles.itemText, {
                      [styles.isActive]:  isActive,
                      [styles.isDeleted]: isDelete
                    })}
                  >
                    <WordHighlighter
                      searchQuery={searchQuery}
                      text={chatItem.topic || "New chat"}
                      excerptLength={20}
                    />
                  </div>
                )}
              </div>
              {!isActive && !isEdit && !isDelete  && (
                <div className={styles.deleteContainer}>
                  <div data-testid='del-btn' className={styles.actionIcon} onClick={(e) => {
                    e.stopPropagation();
                    toggleDelete();
                  }}>
                    <DeleteIcon />
                  </div>
                </div>
              )}
              {isActive && !isEdit && !isDelete && (
                <div className={styles.actContainer}>
                  <div data-testid='edit-btn' className={styles.actionIcon} onClick={toggleEdit}>
                    <EditIcon />
                  </div>
                  <div data-testid='del-btn' className={styles.deleteIcon} onClick={(e) => {
                    e.stopPropagation();
                    toggleDelete()
                  }}>
                    <DeleteIcon />
                  </div>
                </div>
              )}
              {isEdit && (
                <div className={styles.actionsContainer}>
                  {isloading ? (
                    <Spinner extraSmall isWhite={themeMode === 'light'} />
                  ) : (
                    <div data-testid='confirm-edit-btn' className={styles.actionIcon} onClick={onConfirm}>
                      <TickIcon />
                    </div>
                  )}
                  <div data-testid='cancel-edit-btn' className={styles.actionIcon} onClick={onCancel}>
                    <RemoveIcon />
                  </div>
                </div>
              )}
              {isDelete && (
                <div className={styles.actionsContainer}>
                  <div data-testid='cancel-delbtn' className={styles.actionIcon} onClick={(e) => {
                    e.stopPropagation();
                    onCancel()
                  }}>
                    <RemoveIcon />
                  </div>
                  <div data-testid='confirm-del-btn' className={styles.actionIcon} onClick={(e) => {
                    e.stopPropagation();
                    onConfirm()
                  }}>
                    <TickIcon />
                  </div>
                </div>
              )}
            </div>
          </div>
        }
        placement="right"
        theme="light"
      >
        {chatItem.topic || "New Chat"}
      </Tooltip>
    );
  }
);
