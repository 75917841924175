import { Tooltip } from "components";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "redux/hooks";
import classNames from "classnames";
import styles from "./answerContainer.module.scss";
import {
  AudioPlayer,
} from "redux/actions";

import {
  AmplifierIcon,
  StopIcon,
  LoadingIcon,
} from "../icons";
import { FormattedMessage } from "react-intl";
import { useAudio } from "hooks/services/AudioContext";

interface AudioPlayerProps {
  message: string;
  messageId: number;
}

export const AudioController: React.FC<AudioPlayerProps> = ({ message, messageId }) => {


  const userDetail = useSelector((state) => state.authReducer.userDetail);
  const { theme } = useSelector((state) => state.authReducer);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const audioContextRef = useRef<AudioContext | null>(null);
  const sourceRef = useRef<AudioBufferSourceNode | null>(null);

  const audioElementRef = useRef<HTMLAudioElement | null>(null);
  const audioURLRef = useRef<string | null>(null);


  const { playAudio, isPlaying, setIsPlaying, currentMessageId, stopAudio, setCurrentMessageId } = useAudio(); // Use audio context

  const stopAudioPlayback = () => {
    if (audioElementRef.current) {
      setIsLoading(false);
      stopAudio();
      setCurrentMessageId(null);
      audioElementRef.current.pause();
      setIsPlaying(false);
    }
  };

  useEffect(() => {
    audioContextRef.current = new (window.AudioContext ||
      (window as any).webkitAudioContext)();

    return () => {
      sourceRef.current?.stop();
      stopAudioPlayback();
      audioContextRef.current?.close();
    };
  }, []);



  const playAudioInstance = (url: string) => {
    // Create a new audio element if it doesn't exist
    if (!audioElementRef.current) {
      audioElementRef.current = new Audio(url);
      audioElementRef.current.addEventListener("ended", () => {
        setIsPlaying(false);
      });
    }

    // If the audio element already exists and the URL is different, stop current audio
    if (audioElementRef.current.src !== url) {
      stopAudioPlayback();
      audioElementRef.current.src = url;
    }

    playAudio(audioElementRef.current, messageId);
    setIsPlaying(true);
  };

  const onGenerateSpeech = async () => {

    if (audioURLRef.current) {
      playAudioInstance(audioURLRef.current);
      return;
    }

    if (isLoading) return;

    if (!audioElementRef.current) {
      audioElementRef.current = new Audio();
    }

    try {
      setIsLoading(true);
      const data = {
        model: "tts-1",
        voice: "alloy",
        text: message,
      };
      const response = await AudioPlayer(
        data, userDetail?.token,
      );

      const mediaSource = new MediaSource();

      const url = URL.createObjectURL(mediaSource);

      audioURLRef.current = url;
      if (audioElementRef.current) {
        audioElementRef.current.src = url;
      }

      mediaSource.addEventListener("sourceopen", () => {
        const sourceBuffer = mediaSource.addSourceBuffer("audio/mpeg");

        const processData = async () => {
          const { done, value } = await response!.read();

          if (done) {
            if (!sourceBuffer.updating) {
              mediaSource.endOfStream();
            } else {
              sourceBuffer.addEventListener("updateend", () => {
                if (!sourceBuffer.updating) {
                  mediaSource.endOfStream();
                }
              });
            }
            setIsLoading(false);
            return;
          }

          if (value) {
            sourceBuffer.appendBuffer(value);
          }
          if (!sourceBuffer.updating) {
            processData();
          } else {
            sourceBuffer.addEventListener("updateend", processData, {
              once: true,
            });
          }
        };

        processData();
      });

      playAudioInstance(url);
     setTimeout(()=> setIsLoading(false) ,500);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      if (isPlaying) {
        setIsPlaying(false);
      }
    }
  };

  return (
    <>
      <div className={`cursor-pointer`}>
        {isLoading && (currentMessageId !== messageId ) && (
          <div className={`flex items-center justify-center ${classNames(styles.iconContainer,{
            [styles.light]: theme === 'light',
            [styles.dark]: theme === 'dark',
          })}`}>
            <LoadingIcon className={styles.animatespin} theme={theme}/>
          </div>
        )}

        {( currentMessageId === messageId) && (
          <span className={classNames(styles.iconContainer,{
            [styles.light]: theme === 'light',
            [styles.dark]: theme === 'dark',
          })} onClick={stopAudioPlayback}>
            <StopIcon theme={theme}/>
          </span>
        )}
      </div>
      {(!isLoading && ((currentMessageId !== messageId || currentMessageId === null))) && (
        <Tooltip
        regenrate={true}
        tooltipAdjustement={true}
          control={
            <div
              className={classNames(styles.icon, {
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
                [styles.iconContainer]: true,
              })}
              style={{ paddingTop: '2px' }}
              onClick={onGenerateSpeech}
            >
              <span > <AmplifierIcon theme={theme}/> </span>
            </div>
          }
          placement="top"
          theme="light"
        >
          <div>
            <FormattedMessage id="answer.tooltip.audio" />
          </div>
        </Tooltip>
      )}
    </>
  );
};