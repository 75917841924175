import { Fragment, memo, useMemo, useEffect, useRef, useState } from "react";
import classNames from "classnames";

import answerStyles from "../messageContainer/answerContainer.module.scss";
import styles from "./style.module.scss";

import { PreviousIcon, NextIcon } from "../icons";

import { AssistanceIcon } from "../userName";

import { Image } from "./image";

import { IImage, IMessage } from "redux/actions";
import { useSelector } from "redux/hooks";

import { useWindowSize } from "hooks/useWindowSize";
import { useToggleSidebar } from "hooks/services/ToggleSidebarProvider";

interface IProps {
  message: IMessage;
  IGLoading: boolean;
  share?: boolean;
}

export interface IImageToMap {
  id: number;
  size: string;
  upscaled_images_ids: number[];
  images: IImage[];
 
}

export const ImageCarousel = memo(({ message, IGLoading }: IProps) => {
  const { id, images } = message;
  const { theme } = useSelector((state) => state.authReducer);
  const { width } = useWindowSize();
  const { isOpen } = useToggleSidebar();
  const carouselRef = useRef<HTMLDivElement | null>(null);

  const [offsetWidth, setOffsetWidth] = useState<number>(0);
  const [scrollWidth, setScrollWidth] = useState<number>(0);
  const [scrollLeft, setScrollLeft] = useState<number>(0);

  const allImages: IImageToMap[] = useMemo(() => {
    return images?.reduce((acc: IImageToMap[], image: IImage) => {
      const existingImage = acc.find((val) =>
        val.upscaled_images_ids.includes(image.id)
      );

      if (existingImage) {
        existingImage.images.push(image);
      } else {
        const imageObject: IImageToMap = {
          id: image.id,
          size: image.size,
          upscaled_images_ids:
            image.upscaled_images && image.upscaled_images.length
              ? image.upscaled_images.map(
                  (upscaledImage) => upscaledImage.upscale_image_id
                )
              : [],
          images: [image],
        };
        acc.push(imageObject);
      }

      return acc;
    }, []);
  }, [images]);

  useEffect(() => {
    if (carouselRef.current) {
      setTimeout(() => {
        setOffsetWidth((carouselRef.current as HTMLDivElement)?.offsetWidth);
        setScrollWidth((carouselRef.current as HTMLDivElement)?.scrollWidth);
      }, 500);
    }
  }, [allImages, width, isOpen]);

  useEffect(() => {
    const handleScroll = () => {
      if (carouselRef.current) {
        const { scrollLeft } = carouselRef.current;
        setScrollLeft(scrollLeft);
      }
    }; 

    carouselRef.current?.addEventListener("scroll", handleScroll);

    return () => {
      carouselRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, [allImages, width]);

  const onPrevious = () => {
    if (carouselRef.current) {
      const scrollAmount = carouselRef.current.scrollLeft - 500;
      carouselRef.current.scrollTo({
        left: scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const onNext = () => {
    if (carouselRef.current) {
      const scrollAmount = carouselRef.current.scrollLeft + 500;
      carouselRef.current.scrollTo({
        left: scrollAmount,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className={answerStyles.container}>
      <div className={answerStyles.leftContainer}>
        <div
          className={classNames({
            [answerStyles.assistanceIconAnimation]:
              allImages?.length === 0 && !message?.content && IGLoading === true,
          })}
        >
          <AssistanceIcon />
        </div>
        <div className={styles.carouselContainer}>
          {allImages?.length > 0 && (
            <Fragment>
              {scrollLeft > 0 && (
                <button
                  className={classNames(
                    styles.button,
                    styles.navigationalButton,
                    {
                      [styles.prev]: true,
                    }
                  )}
                  onClick={onPrevious}
                  data-testid='previous-image'
                >
                  <PreviousIcon />
                </button>
              )}
              <div
                ref={carouselRef}
                className={classNames(styles.carousel, {
                  [styles.isSidebarOpen]: isOpen,
                })}
              >
                {allImages.map((image, index) => (
                  <Image
                    key={`image ${index}`}
                    messageId={Number(id)}
                    chatId={message.chat_id as number}
                    images={image.images}
                    initialIndex={image.images.length - 1}
                  />
                ))}
              </div>
              {scrollWidth > offsetWidth &&
                !(scrollWidth - offsetWidth <= scrollLeft) && (
                  <button
                    className={classNames(
                      styles.button,
                      styles.navigationalButton,
                      {
                        [styles.next]: true,
                      }
                    )}
                    onClick={onNext}
                    data-testid='next-image'
                  >
                    <NextIcon />
                  </button>
                )}
            </Fragment>
          )}
          {message.content && (
            <div
              className={classNames(styles.text, {
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
              })}
            >
              {message.content}
            </div>
          )}
        </div>
      </div>
    </div>
  );
});
