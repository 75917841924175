import { memo, useState, useEffect, useRef, ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import classNames from "classnames";
import styles from "./renderTable.module.scss";

import { useSelector } from "redux/hooks";

interface Iprops {
  children: ReactNode;
}

export const RenderTable = memo(({ children }: Iprops) => {
  const { theme } = useSelector((state) => state.authReducer);

  const [isCopied, setIsCopied] = useState<boolean>(false);
  const tableRef = useRef<HTMLTableElement>(null);

  useEffect(() => {
    const intervalID = setTimeout(() => {
      if (isCopied) setIsCopied(false);
    }, 1000);

    return () => clearInterval(intervalID);
  }, [isCopied]);

  const getTableData = () => {
    const table = tableRef.current;

    if (!table) {
      console.error("Table reference is null");
      return;
    }

    const rows = table.getElementsByTagName("tr");

    const tableData: string[][] = [];

    const headerRow = Array.from(rows[0].getElementsByTagName("th")).map(
      (cell) => cell.innerText
    );
    tableData.push(headerRow);

    for (let i = 1; i < rows.length; i++) {
      const cells = rows[i].getElementsByTagName("td");
      const rowData = Array.from(cells).map((cell) => cell.innerText);
      tableData.push(rowData);
    }

    const textData = tableData.map((row) => row.join("\t")).join("\n");

    navigator.clipboard
      .writeText(textData)
      .then(() => {
        setIsCopied(true);
      })
      .catch((err) => {
        console.error("Copy error", err);
      });
  };

  return (
    <div
      className={classNames(styles.tableContainer, {
        [styles.tableContainerLight]: theme === "light",
        [styles.tableContainerDark]: theme === "dark",
      })}
    >
      <table ref={tableRef}>{children}</table>
      <div className={styles.rows}>
        <div data-testid='get-table-data' className={styles.copyTableBtn} onClick={getTableData}>
          {isCopied ? (
            <span>
              <FormattedMessage id="answer.tooltip.copied" />
            </span>
          ) : (
            <span className={styles.cursor}>
              <FormattedMessage id="answer.tooltip.copyTable" />
            </span>
          )}
        </div>
      </div>
    </div>
  );
});
