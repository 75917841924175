import styles from "./userName.module.scss";

interface IProps {
  name?: string;
  email?: string;
  className?: string;
}

const getCharacters = ({ name, email }: IProps): string => {
  if (name) {
    const splittedName = name?.split(" ");
    if (splittedName.length >= 2) {
      return `${name.split(" ")[0].substring(0, 1)} ${name
        .split(" ")[1]
        .substring(0, 1)}`;
    } else {
      return "D U";
    }
  } else {
    return `${email?.substring(0, 1)}`;
  }
};

export const UserName = (props: IProps) => {
  return (
    <div className={`${styles.nameContainer} ${props.className}`}>
      {getCharacters(props).toUpperCase()}
    </div>
  );
};
