import { IAction, TYPES } from "../types";
import { produce } from "immer";
import { IMember, ITeam, IUpdateMemberDailyLimitBody, IUpdateMemberRoleBody, UserRolesType } from "../actions";

export interface ITeamState {
    invitations: IMember[];
    members: IMember[];
    teams: ITeam[];
};

interface IGetTeamsAction extends IAction {
    payload: ITeam[];
};

interface IGetTeamMembersAction extends IAction {
    payload: {
        invitations: IMember[],
        members: IMember[]
    };
};

interface IInviteTeamMemberAction extends IAction {
    payload: {
        invitedMember: IMember;
    };
};

interface IUpdateMemberRoleAction extends IAction {
    payload: {
        member: IUpdateMemberRoleBody;
    };
};

interface IUpdateMemberCreditLimitAction extends IAction {
    payload: {
        creditLimitMember: IUpdateMemberDailyLimitBody;
    };
};

interface IRemoveMemberAction extends IAction {
    payload: {
        member_id: number;
    };
};

interface ICancelInviteAction extends IAction {
    payload: {
        invitation_id: number;
    };
};


type Action = IGetTeamsAction &
IGetTeamMembersAction &
IInviteTeamMemberAction &
IUpdateMemberRoleAction &
IUpdateMemberCreditLimitAction &
IRemoveMemberAction &
ICancelInviteAction;

const state: ITeamState = {
    invitations: [],
    members: [],
    teams: [],
};

export const teamReducer = (mState = {...state}, action: Action): ITeamState => {
    switch (action.type) {
        case TYPES.GET_ALL_TEAMS:
            return produce(mState, (dState) => {dState.teams = action.payload});
        
        case TYPES.GET_TEAM_MEMBERS:
            return produce(mState, (dState) => {
                dState.members = action.payload.members;
                dState.invitations = action.payload.invitations;
            });

        case TYPES.GET_TEAM_INFO:
            return produce(mState, (dState) => {
                return dState;
            });

        case TYPES.INVITE_TEAM_MEMBER:
            return produce(mState, (dState) => {
                dState.invitations = [...dState.invitations, action.payload.invitedMember];
            });

        case TYPES.UPDATE_MEMBER_ROLE:
            return produce(mState, (dState) => {
                let index = dState.members.findIndex(member => member.id === action.payload.member.user_id);
                if (index >= 0) dState.members[index].role = action.payload.member.role as UserRolesType;
            });

        case TYPES.UPDATE_MEMBER_DAILY_LIMIT:
            return produce(mState, (dState) => {
                let index = dState.members.findIndex(member => member.id === action.payload.creditLimitMember.user_id);
                if (index >= 0) dState.members[index].daily_limit = action.payload.creditLimitMember.credits;
            });

        case TYPES.REMOVE_TEAM_MEMBER:
            return produce(mState, (dState) => {
                dState.members = dState.members.filter(member => member.id !== action.payload.member_id);
            });

        case TYPES.CANCEL_INVITE:
            return produce(mState, (dState) => {
                dState.invitations = dState.invitations.filter(member => member.id !== action.payload.invitation_id);
            });

        default:
            return {...mState};
    }
};